import { render, staticRenderFns } from "./kefu.vue?vue&type=template&id=527c2816&scoped=true&"
import script from "./kefu.vue?vue&type=script&lang=js&"
export * from "./kefu.vue?vue&type=script&lang=js&"
import style0 from "./kefu.vue?vue&type=style&index=0&id=527c2816&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527c2816",
  null
  
)

export default component.exports